// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("/opt/build/repo/src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-vip-cars-sarajevo-js": () => import("/opt/build/repo/src/pages/en/about-vip-cars-sarajevo.js" /* webpackChunkName: "component---src-pages-en-about-vip-cars-sarajevo-js" */),
  "component---src-pages-en-index-js": () => import("/opt/build/repo/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-luxury-cars-sarajevo-js": () => import("/opt/build/repo/src/pages/en/luxury-cars-sarajevo.js" /* webpackChunkName: "component---src-pages-en-luxury-cars-sarajevo-js" */),
  "component---src-pages-en-page-2-js": () => import("/opt/build/repo/src/pages/en/page-2.js" /* webpackChunkName: "component---src-pages-en-page-2-js" */),
  "component---src-pages-en-privacy-policy-of-vip-cars-sarajevo-js": () => import("/opt/build/repo/src/pages/en/privacy-policy-of-vip-cars-sarajevo.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-of-vip-cars-sarajevo-js" */),
  "component---src-pages-en-reserve-your-luxury-vip-car-js": () => import("/opt/build/repo/src/pages/en/reserve-your-luxury-vip-car.js" /* webpackChunkName: "component---src-pages-en-reserve-your-luxury-vip-car-js" */),
  "component---src-pages-en-thankyou-js": () => import("/opt/build/repo/src/pages/en/thankyou.js" /* webpackChunkName: "component---src-pages-en-thankyou-js" */),
  "component---src-pages-en-where-to-rent-luxury-cars-sarajevo-js": () => import("/opt/build/repo/src/pages/en/where-to-rent-luxury-cars-sarajevo.js" /* webpackChunkName: "component---src-pages-en-where-to-rent-luxury-cars-sarajevo-js" */),
  "component---src-pages-gallery-s-class-js": () => import("/opt/build/repo/src/pages/gallery-s-class.js" /* webpackChunkName: "component---src-pages-gallery-s-class-js" */),
  "component---src-pages-gallery-v-class-js": () => import("/opt/build/repo/src/pages/gallery-v-class.js" /* webpackChunkName: "component---src-pages-gallery-v-class-js" */),
  "component---src-pages-gallery-viano-js": () => import("/opt/build/repo/src/pages/gallery-viano.js" /* webpackChunkName: "component---src-pages-gallery-viano-js" */),
  "component---src-pages-gallery-vito-js": () => import("/opt/build/repo/src/pages/gallery-vito.js" /* webpackChunkName: "component---src-pages-gallery-vito-js" */),
  "component---src-pages-gallery-volvo-js": () => import("/opt/build/repo/src/pages/gallery-volvo.js" /* webpackChunkName: "component---src-pages-gallery-volvo-js" */),
  "component---src-pages-gdje-iznajmiti-luksuzno-vozilo-js": () => import("/opt/build/repo/src/pages/gdje-iznajmiti-luksuzno-vozilo.js" /* webpackChunkName: "component---src-pages-gdje-iznajmiti-luksuzno-vozilo-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luksuzna-vozila-js": () => import("/opt/build/repo/src/pages/luksuzna-vozila.js" /* webpackChunkName: "component---src-pages-luksuzna-vozila-js" */),
  "component---src-pages-o-vip-cars-sarajevo-js": () => import("/opt/build/repo/src/pages/o-vip-cars-sarajevo.js" /* webpackChunkName: "component---src-pages-o-vip-cars-sarajevo-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-politika-privatnosti-vip-cars-sarajevo-js": () => import("/opt/build/repo/src/pages/politika-privatnosti-vip-cars-sarajevo.js" /* webpackChunkName: "component---src-pages-politika-privatnosti-vip-cars-sarajevo-js" */),
  "component---src-pages-rezervisite-vase-vip-cars-vozilo-js": () => import("/opt/build/repo/src/pages/rezervisite-vase-vip-cars-vozilo.js" /* webpackChunkName: "component---src-pages-rezervisite-vase-vip-cars-vozilo-js" */),
  "component---src-pages-thankyou-js": () => import("/opt/build/repo/src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

